import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
//Types
import { SectionTabulated } from '../../../types/sections'
// Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Flex, Heading, Text } from '../../../atoms'
import FeatureItem from './FeatureItem'

export interface TabulatedProps {
  data: SectionTabulated
}

const Tabulated: React.FC<TabulatedProps> = ({ data }) => {
  const { id, sectionId, title, featuresTabs } = data
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const tabsParentRef = useRef(null)
  const titleTabsRefs = useRef(new Map())

  const [indicatorVariants, setIndicatorVariants] = useState({})

  useEffect(() => {
    const listener = () => {
      if (!tabsParentRef || !tabsParentRef.current) {
        return {}
      }
      //Ignore tabsParentRef.current is possibly null warning because
      //we check just above :rolling_eyes:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const parentOffset = tabsParentRef.current.offsetLeft
      const variants = featuresTabs.reduce((variants, tab) => {
        const element = titleTabsRefs.current.get(tab.id)
        const tabOffset = element.offsetLeft
        return {
          ...variants,
          [tab.id]: {
            transform: `translate3d(${tabOffset - parentOffset}px, 0,0)`,
            width: element.offsetWidth + 'px',
          },
        }
      }, {})
      setIndicatorVariants(variants)
    }
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [featuresTabs])

  useEffect(() => {
    //Tricks to update variants
    setTimeout(() => window.dispatchEvent(new Event('resize')))
  }, [])

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      pt={[80, 80, 120]}
      pb={[80, 80, 120]}
      overflow={'hidden'}
      backgroundColor={'dark'}
      backgroundImage={[
        'radial-gradient(circle at 0% 60%,rgba(248,144,144,0.14),rgba(255,255,255,0) 30%), radial-gradient(circle at 100% 40%,rgba(109,216,252,0.14),rgba(255,255,255,0) 30%)',
        'radial-gradient(circle at 0% 55%,rgba(248,144,144,0.14),rgba(255,255,255,0) 14%), radial-gradient(circle at 100% 40%,rgba(109,216,252,0.14),rgba(255,255,255,0) 14%)',
      ]}
    >
      <Grid>
        {/* HEADLINE */}
        <Box mb={[48, 64]}>
          <Row>
            <Col xs={12}>
              <Heading as={'h3'} color={'white'}>
                {title}
              </Heading>
            </Col>
          </Row>
        </Box>
        {/* TABS */}
        <Box mb={[80, 80, 100]}>
          <Row>
            <Col xs={12}>
              <Flex ref={tabsParentRef}>
                {featuresTabs.map((t, i) => {
                  return (
                    <Box
                      key={t.id}
                      mr={i + 1 < featuresTabs.length ? [24, 48, 80] : undefined}
                      py={[4, 5]}
                      clickable
                      onClick={() => setActiveIndex(i)}
                      ref={(node) => titleTabsRefs.current.set(t.id, node)}
                    >
                      <Text
                        fontSize={[3, 4]}
                        fontWeight={'semi'}
                        color={'white'}
                        opacity={activeIndex === i ? 1 : 0.64}
                        lineHeight={1.2}
                      >
                        {t.title}
                      </Text>
                    </Box>
                  )
                })}
              </Flex>
              <Box position={'relative'}>
                <Box opacity={0.16} bg={'white'} height={'4px'} borderRadius={'circle'} />
                <motion.div
                  variants={indicatorVariants}
                  animate={featuresTabs[activeIndex] ? featuresTabs[activeIndex].id : 'none'}
                  transition={{
                    transform: { type: 'tween', duration: 0.7 },
                    width: { type: 'tween', stiffness: 120 },
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    height: 4,
                    background: '#6DD8FC',
                    borderRadius: 2,
                    width: 100,
                    left: 0,
                  }}
                />
              </Box>
            </Col>
          </Row>
        </Box>
        {/* TAB CONTENT */}
        <Row>
          {featuresTabs[activeIndex] &&
            featuresTabs[activeIndex].features.map((f, i, a) => {
              return (
                <Col key={f.id} xs={12} md={6}>
                  <Box mb={i + 1 < a.length ? [8, 10, 74] : undefined}>
                    <FeatureItem icon={f.asset} title={f.name} content={f.content} />
                  </Box>
                </Col>
              )
            })}
        </Row>
      </Grid>
    </Box>
  )
}

export default Tabulated
