import React from 'react'
import { GatsbyImageBasicProps } from '../../../../types/images'
import { Box, Flex, Image, Text } from '../../../../atoms'

interface FeatureItemProps {
  icon: GatsbyImageBasicProps
  title: string
  content: string
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, title, content }) => {
  return (
    <Flex alignItems={'start'}>
      <Box flex={'0 0 64px'} height={64} mr={6}>
        <Image image={icon.gatsbyImageData} alt={icon.alt || ''} />
      </Box>
      <Box flex={'1'} pr={[0, 0, 0, 4]}>
        {!!title && (
          <Text color={'white'} fontWeight={'semi'}>
            {title}
          </Text>
        )}
        {!!content && (
          <Box mt={3}>
            <Text color={'white'} fontSize={2}>
              {content}
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default FeatureItem
